.graphical-question {
  width: 100%;
  height: 200px;
  margin-bottom: 40px;
}

.passage-outer {
  height: 200px;
  overflow-y: scroll;
}

.neonText {
  font-size: 3rem;
  color: #fff;
  text-shadow: 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #00f, 0 0 70px #00f, 0 0 80px #00f,
    0 0 100px #12a89b, 0 0 150px #0d3ca1;
}
